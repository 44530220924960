
import React from "react"
import PageTransition from 'gatsby-plugin-page-transitions';
// import { Link } from "gatsby"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import WhoAreWe from "../../components/who-are-we"
import Sponsores from "../../components/sponsores"
import Realisation from "../../components/realisation"

class Sodexo extends React.Component {
  render = () => (
    
        <Layout>
            <SEO title="DHJ" keywords={[`gatsby`, `application`, `react`]} />
            <div className="hero hero--smallest hero--project-dhj inuit-box inuit-box--flush">
                <h2 className="hero__message hero__message--bigger hero__message--center">
                
                </h2>
            </div>
            <article className="article project inuit-u-p0">
                <div className="article__body article__body--has-extra">

                    <aside className="article__extra">
                        <p className="article__para inuit-u-p0">
                            <a className="project__link" href="https://sodexo.ma" title="" target="_blank">
                                <img src="/img/clients/DHJ.png" alt="" className="project__logo" />
                            </a>
                        </p>

                        <p className="article__para inuit-u-p0">
                            <span className="project__detail">DHJ.</span>
                            <span className="project__detail project__detail--emphasis">
                                < a className = "project__link"
                                href = "https://dhjfoot.com/"
                                title = "PRECISION SYSTEMS"
                                target = "_blank" >dhjfoot.com</a>
                            </span>
                            <span className="project__detail">Year 2019</span>
                        </p>
                    </aside>

                    <p className="">
                        <h1 className="article__heading m-top">DHJ.</h1>
                        <p className="article__para">
                            L 'application officielle du Difaa el Hassani el Jadidii (DHJ) vous permet de suivre toute l'actualité de l 'équipe première du club. Vous aurez rendez-vous avec des photos exclusives des joueurs et des interviews inédites d'avant et après match Classements, calendriers et résultats, autant d’ occasions de partager votre amour du football et la passion de votre club de football préféré.

                        </p>
                    </p>

                </div>


                <p className="article__belt" style={{ marginTop: "0" }}>
                    < img className = "article__belt-image"
                    src = "/img/projects/dhj/flyingui.png"
                    alt = "mobile-apps" / >
                </p>


                {/* <p className="article__belt inuit-u-mb0">
                    < img className = "article__belt-image"
                    src = "/img/projects/sodexo-site/anime-services.gif"
                    alt = "branding" / >
                </p> */}

                <p className="article__belt inuit-u-mb0">
                    < img className = "article__belt-imagered"
                    src = "/img/projects/dhj/1.png"
                    alt = "branding" / >
                </p>
                <br /><br />
            </article>
            <Realisation/>

            <Sponsores />
            <WhoAreWe />
        </Layout>
    
  )

}

export default Sodexo
